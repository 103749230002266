import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import styled from "styled-components";

const Navbar = ({ navigation, pageTitle }) => {
	const { selectedWebsite } = useSelector((state) => state.websites);
	const {
		params: { websiteId },
	} = useSelector((state) => state.page);

	// eslint-disable-next-line no-undef
	const topHeight = process.env.REACT_APP_ENVIRONMENT === "production" ? "0px" : "23px";
	const id = selectedWebsite ? selectedWebsite?.id : websiteId;

	return (
		<Navigation topHeight={topHeight}>
			<PageTitle>{pageTitle}</PageTitle>
			<NavigationBar>
				{navigation.map(({ text, queryString, href }, i) => {
					if (queryString && id) {
						return (
							<a
								key={`${text}_${i}`}
								href={`${href}?website_id=${id}`}
								data-qa={`site-nav_${text.toLowerCase()}`}
							>
								{text}
							</a>
						);
					}
					return (
						<a
							data-qa={`site-nav_${text.toLowerCase()}`}
							key={`${text}_${i}`}
							href={`${href}`}
						>
							{text}
						</a>
					);
				})}
			</NavigationBar>
		</Navigation>
	);
};

Navbar.propTypes = {
	pageTitle: PropTypes.string,
	navigation: PropTypes.array.isRequired,
};

const Navigation = styled.nav`
	position: absolute;
	z-index: 10;
	background: #2a2c31;
	width: 100%;
	display: flex;
	flex-direction: row;
	top: ${({ topHeight }) => topHeight};
	align-items: center;
	height: 22px;
`;

const PageTitle = styled.div`
	color: #e9eef1;
	font-size: 10.5px;
	text-transform: capitalize;
	font-weight: 400;
	padding-left: 20px;
`;

const NavigationBar = styled.div`
	margin-left: auto;
	display: flex;

	a {
		color: #e9eef1;
		font-size: 10.5px;
		cursor: pointer;
		text-transform: capitalize;
		font-weight: 400;
		padding-left: 20px;
		:last-child {
			padding-right: 10px;
		}
	}
`;

export default Navbar;
