import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Zap } from "react-feather";
import { ToolTip } from "../../../components";
import { ICON_SIZES } from "../../../constants/common";

const CampaignTestLink = ({ campaignId, isGray, scheduledUTCValue, scheduledTime }) => {
	const { selectedWebsite: website } = useSelector((state) => state.websites);

	const siteLink = website?.attributes?.website;
	const appendDate = scheduledTime ? `#wknddate${scheduledUTCValue}` : "";

	const link = `http://${siteLink}?bxtest${campaignId}#wkndtest${appendDate}`;

	const clickToCopy = () => {
		navigator.clipboard.writeText(link);
	}

	return (
		<LinkContainer isGray={isGray}>
			<ToolTip position="bottom" text="Click to copy test link">
				<Zap aria-label="test link button" style={{cursor: "pointer"}} size={ICON_SIZES.medium} onClick={clickToCopy}/>
			</ToolTip>
			<ToolTip position="bottom" text="Test link">
				<Link data-qa="campaign-test-link" target="_blank" href={link} isGray={isGray}>
					<Label>{campaignId}</Label>
				</Link>
			</ToolTip>
		</LinkContainer>
	);
};

const LinkContainer = styled.div`
	margin: 0 11px;
	opacity: ${({ isGray }) => (isGray ? "0.4" : "1")};
`;

const Link = styled.a`
	&:hover > * {
		color: ${({ isGray }) => (isGray ? "rgb(98, 97, 97)" : "black")};
	}
`;

const Label = styled.span`
	font-size: 12px;
	margin-left: 8px;
`;

CampaignTestLink.propTypes = {
	campaignId: PropTypes.string.isRequired,
	isGray: PropTypes.bool,
	scheduledUTCValue: PropTypes.string,
	scheduledTime: PropTypes.bool,
};

export default CampaignTestLink;
