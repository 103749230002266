import sortBy from "lodash/sortBy";
import { toEspProfileDto } from "../../pages/websites/edit/esp-profiles/utils";
import { DEFAULT_RESPONSE_STATE } from "./constants";

/**
 * These are a 1:1 mapping to the data for now.
 * We can refine as needed to obtain more specific/formatted data sets.
 */
export const selectWebsiteId = ({ page }) => page?.params?.websiteId;

export const selectFetchedEspProfiles = ({ espProfiles }) => espProfiles?.fetchedProfiles;

export const selectFetchedEspProfile = ({ espProfiles }) => espProfiles?.fetchedProfile;

export const selectCreatedEspProfile =  ({ espProfiles }) => espProfiles?.createdProfile;

export const selectClonedEspProfile =  ({ espProfiles }) => espProfiles?.clonedProfile;

export const selectUpdatedEspProfile = ({ espProfiles }) => espProfiles?.updatedProfile;

export const selectDeletedEspProfile = ({ espProfiles }) => espProfiles?.deletedProfile;

export const selectEspProfileCampaigns = (espProfileId) => ({ espProfiles }) => espProfiles?.campaigns[espProfileId];

export const selectFormattedEspProfileCampaigns = (espProfileId) => ({ espProfiles }) => {
	const espProfileCampaigns = espProfiles?.campaigns[espProfileId];

	if (!espProfileCampaigns) {
		return DEFAULT_RESPONSE_STATE;
	}

	const { data, error, isLoading } = espProfiles?.campaigns[espProfileId] || {};
	const campaigns = data?.map(({ attributes }) => ({ name: attributes.name, id: attributes.id }));
	return {
		data: campaigns,
		error,
		isLoading,
	};
};

export const selectEsps = ({ espProfiles }) => espProfiles?.esps;

export const selectFormattedEsps = ({ espProfiles }) => {
	const esps = espProfiles?.esps?.data || []
	const formattedEsps = esps.map(({ attributes }) => ({
		id: attributes.id,
		name: attributes.name,
		fields: attributes.fields,
	}));

	return sortBy(formattedEsps, 'name');
};

export const selectFormattedEspProfiles = ({ espProfiles }) => {
	const profiles = espProfiles?.fetchedProfiles?.data || [];
	const esps = espProfiles?.esps?.data || [];

	if (profiles.length === 0 || esps.length === 0) {
		return [];
	}

	return profiles.map(toEspProfileDto);
};

export const selectProfileNames = ({ espProfiles }) => {
	const profiles = espProfiles?.fetchedProfiles?.data || [];
	const esps = espProfiles?.esps?.data || [];

	if (profiles.length === 0 || esps.length === 0) {
		return [];
	}

	return profiles.map(({ attributes }) => attributes.name);
};