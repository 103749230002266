import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Button, Loader, ReactLink } from "../../../components";
import { selectFormattedEspProfileCampaigns } from "../../../redux/espProfiles/selectors";
import { useEspProfileActions } from "../../../hooks/esp-profiles/useEspProfileActions";

const ESPProfileCampaignsModal = ({ websiteId, profileName, id, toggleModal }) => {
	// data will be updated when obtain more specific/formatted data sets
	const { data: campaigns, error, isLoading } = useSelector(selectFormattedEspProfileCampaigns(id));

	const { getCampaignsByProfile } = useEspProfileActions(websiteId);

	useEffect(() => {
		getCampaignsByProfile(id);
	}, []);

	const renderContent = () => {
		const hasCampaigns = (campaigns || []).length > 0;
		const hasLoaded = !isLoading;

		if (error && hasLoaded) {
			<p style={{ textAlign: "center" }}>
				Error fetching campaigns for: {profileName}.
			</p>
		}

		if (hasCampaigns && hasLoaded) {
			return campaigns.map(campaign =>
				<a 
					key={campaign.id}
					href={`/experiences/cms-legacy/${campaign.id}/edit`}
					target="_blank"
					rel="noreferrer">
					<Row key={campaign.id}>
						{campaign.name}
					</Row>
				</a>
			);
		} else if (hasLoaded) {
			return (
				<p style={{ textAlign: "center" }}>
					There are no campaigns related to: {profileName}.
				</p>
			);
		}
	};

	return (
		<Modal>
			<Header>
				{profileName}: Campaigns
			</Header>
			<Loader isLoading={isLoading} />
			<RowContainer>
				{renderContent()}
			</RowContainer>
			<ButtonDiv>
				<Button theme="blue" onClick={() => toggleModal()}>
					Close
				</Button>
			</ButtonDiv>
		</Modal>
	);
};

const Modal = styled.div`
	overflow-y: auto;
	max-height: 80vh;
	min-height: 40vh;
	max-width: 80vw;
	min-width: 60vw;
`;

const ButtonDiv = styled.div`
	text-align: center;
	margin-top: 10px;
`;

const RowContainer = styled.div`
	min-height: 30vh;
`;

const Row = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	padding: 0 11px;
	width: 97%;
	overflow-y: auto;
	height: 42px;
	border: 1px solid rgb(232, 232, 232);
	margin-top: 1px;
`;

const Header = styled.div`
	display: block;
	margin-top: 5px;
	margin-bottom: 20px;
	padding: 0;
	font-size: 18px;
	font-weight: bold;
	width: 100%;
	text-align: center;
	color: #535252;
`;

ESPProfileCampaignsModal.propTypes = {
	websiteId: PropTypes.string,
	profileName: PropTypes.string.isRequired,
	id: PropTypes.string,
	toggleModal: PropTypes.func.isRequired,
};

export default ESPProfileCampaignsModal;