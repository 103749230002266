/**
 * ESP Profiles Slice
 */

import { createSlice } from "@reduxjs/toolkit";

import {
	createProfileThunk,
	cloneProfileThunk,
	getProfileThunk,
	getProfilesThunk,
	getCampaignsByProfileThunk,
	getEspsThunk,
	updateProfileThunk,
	deleteProfileThunk,
} from "./thunks";

import { DEFAULT_RESPONSE_STATE } from "./constants";

const initialState = {
	selectedProfileId: null,
	createdProfile: { ...DEFAULT_RESPONSE_STATE },
	clonedProfile: { ...DEFAULT_RESPONSE_STATE },
	fetchedProfile: { ...DEFAULT_RESPONSE_STATE },
	fetchedProfiles: { ...DEFAULT_RESPONSE_STATE },
	updatedProfile: { ...DEFAULT_RESPONSE_STATE },
	deletedProfile: { ...DEFAULT_RESPONSE_STATE },
	/**
	 * campaigns will be keyed by espProfileId to fetch using selectors
	 * The fetch will occur when clicking on the [search] icon and stored
	 * in redux as a cached value.
	 */
	campaigns: {
	},
	esps: { ...DEFAULT_RESPONSE_STATE },
};

const espProfilesSlice = createSlice({
	name: "espProfiles",
	initialState,
	reducers: {
		selectProfile: (state, action) => {
			state.selectedProfileId = action.payload;
		},
		clearCreatedProfile: (state) => {
			state.createdProfile = { ...DEFAULT_RESPONSE_STATE };
		},
		clearClonedProfile: (state) => {
			state.clonedProfile = { ...DEFAULT_RESPONSE_STATE };
		},
		clearUpdatedProfile: (state) => {
			state.updatedProfile = { ...DEFAULT_RESPONSE_STATE };
		},
		clearDeletedProfile: (state) => {
			state.deletedProfile = { ...DEFAULT_RESPONSE_STATE };
		},
	},
	extraReducers: (builder) => {
		/** Create Profile */
		builder.addCase(createProfileThunk.pending, (state) => {
			state.createdProfile = {
				...DEFAULT_RESPONSE_STATE,
				isLoading: true,
			};
		});

		builder.addCase(createProfileThunk.fulfilled, (state, action) => {
			state.createdProfile = {
				...DEFAULT_RESPONSE_STATE,
				data: action.payload.data,
			};
		});

		builder.addCase(createProfileThunk.rejected, (state, action) => {
			state.createdProfile = {
				...DEFAULT_RESPONSE_STATE,
				error: action.error,
			};
		});

		/** Clone Profile */
		builder.addCase(cloneProfileThunk.pending, (state) => {
			state.clonedProfile = {
				...DEFAULT_RESPONSE_STATE,
				isLoading: true,
			};
		});

		builder.addCase(cloneProfileThunk.fulfilled, (state, action) => {
			state.clonedProfile = {
				...DEFAULT_RESPONSE_STATE,
				data: action.payload.data,
			};
		});

		builder.addCase(cloneProfileThunk.rejected, (state, action) => {
			state.clonedProfile = {
				...DEFAULT_RESPONSE_STATE,
				error: action.error,
			};
		});

		/** Get Profile */
		builder.addCase(getProfileThunk.pending, (state) => {
			state.fetchedProfile = {
				...DEFAULT_RESPONSE_STATE,
				isLoading: true,
			};
		});

		builder.addCase(getProfileThunk.fulfilled, (state, action) => {
			state.fetchedProfile = {
				...DEFAULT_RESPONSE_STATE,
				data: action.payload.data,
			};
		});

		builder.addCase(getProfileThunk.rejected, (state, action) => {
			state.fetchedProfile = {
				...DEFAULT_RESPONSE_STATE,
				error: action.error,
			};
		});

		/** Get Profiles */
		builder.addCase(getProfilesThunk.pending, (state) => {
			state.fetchedProfiles = {
				...DEFAULT_RESPONSE_STATE,
				isLoading: true,
			};
		});

		builder.addCase(getProfilesThunk.fulfilled, (state, action) => {
			state.fetchedProfiles = {
				...DEFAULT_RESPONSE_STATE,
				data: action.payload.data,
			};
		});

		builder.addCase(getProfilesThunk.rejected, (state, action) => {
			state.fetchedProfiles = {
				...DEFAULT_RESPONSE_STATE,
				error: action.error,
			};
		});

		/** Update Profile */
		builder.addCase(updateProfileThunk.pending, (state) => {
			state.updatedProfile = {
				...DEFAULT_RESPONSE_STATE,
				isLoading: true,
			};
		});

		builder.addCase(updateProfileThunk.fulfilled, (state, action) => {
			state.updatedProfile = {
				...DEFAULT_RESPONSE_STATE,
				data: action.payload.data,
			};
		});

		builder.addCase(updateProfileThunk.rejected, (state, action) => {
			state.updatedProfile = {
				...DEFAULT_RESPONSE_STATE,
				error: action.error,
			};
		});

		/** Delete Profile */
		builder.addCase(deleteProfileThunk.pending, (state) => {
			state.deletedProfile = {
				...DEFAULT_RESPONSE_STATE,
				isLoading: true,
			};
		});

		builder.addCase(deleteProfileThunk.fulfilled, (state, action) => {
			state.deletedProfile = {
				...DEFAULT_RESPONSE_STATE,
				data: action.payload.data,
			};
		});

		builder.addCase(deleteProfileThunk.rejected, (state, action) => {
			state.deletedProfile = {
				...DEFAULT_RESPONSE_STATE,
				error: action.error,
			};
		});

		/** Get Campaigns by Profild */
		builder.addCase(getCampaignsByProfileThunk.pending, (state, action) => {
			const { campaigns } = state;
			const { espProfileId } = action?.meta?.arg;

			state.campaigns = {
				...campaigns,
				[espProfileId]: {
					...DEFAULT_RESPONSE_STATE,
					isLoading: true,
				},
			};
		});

		builder.addCase(getCampaignsByProfileThunk.fulfilled, (state, action) => {
			const { campaigns } = state;
			const { espProfileId } = action?.meta?.arg;
			state.campaigns = {
				...campaigns,
				[espProfileId]: {
					...DEFAULT_RESPONSE_STATE,
					data: action.payload.data,
				},
			};
		});

		builder.addCase(getCampaignsByProfileThunk.rejected, (state, action) => {
			const { campaigns } = state;
			const { espProfileId } = action?.meta?.arg;

			state.campaigns = {
				...campaigns,
				[espProfileId]: {
					...DEFAULT_RESPONSE_STATE,
					error: action.error,
				},
			};
		});

		/** Get ESPs */
		/** Get Campaigns by Profild */
		builder.addCase(getEspsThunk.pending, (state) => {
			state.esps = {
				...DEFAULT_RESPONSE_STATE,
				isLoading: true,
			};
		});

		builder.addCase(getEspsThunk.fulfilled, (state, action) => {
			state.esps = {
				...DEFAULT_RESPONSE_STATE,
				data: action.payload.data,
			};
		});

		builder.addCase(getEspsThunk.rejected, (state, action) => {
			state.esps = {
				...DEFAULT_RESPONSE_STATE,
				error: action.error,
			};
		});
	}
});

export const {
	selectProfile,
	clearCreatedProfile,
	clearClonedProfile,
	clearUpdatedProfile,
	clearDeletedProfile,
} = espProfilesSlice.actions;

export default espProfilesSlice.reducer;
