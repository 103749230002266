import { configureStore } from "@reduxjs/toolkit";
import page from "../redux/page/slice";
import report from "../redux/report/slice";
import esps from "../redux/esps/slice";
import esp from "../redux/esp/slice";
import user from "../redux/user/slice";
import uploadFiles from "../redux/uploadFiles/slice";
import topStats from "../redux/topStats/slice";
import websites from "../redux/websites/slice";
import industries from "../redux/industries/slice";
import contracts from "../redux/contracts/slice";
import verticals from "../redux/verticals/slice";
import campaigns from "../redux/campaigns/slice";
import purposes from "../redux/purposes/slice";
import campaignTypes from "../redux/campaignTypes/slice";
import campaignTemplates from "../redux/campaignTemplates/slice";
import parentCampaignTypes from "../redux/parentCampaignTypes/slice";
import creatives from "../redux/creatives/slice";
import fonts from "../redux/fonts/slice";
import website from "../redux/website/slice";
import inboxCampaigns from "../redux/inboxCampaigns/slice";
import inboxSeries from "../redux/inboxSeries/slice";
import inbox from "../redux/inbox/slice";
import inboxCampaignTypes from "../redux/inboxCampaignTypes/slice";
import espProfiles from "./espProfiles/slice";

export const store = configureStore({
	reducer: {
		page,
		report,
		esps,
		esp,
		user,
		uploadFiles,
		topStats,
		website,
		websites,
		industries,
		contracts,
		verticals,
		campaigns,
		purposes,
		campaignTypes,
		campaignTemplates,
		parentCampaignTypes,
		creatives,
		fonts,
		inboxCampaigns,
		inboxSeries,
		inbox,
		inboxCampaignTypes,
		espProfiles,
	},
	middleware: (getDefaultMiddleware) => getDefaultMiddleware({
			immutableCheck: false,
			serializableCheck: false,
		})
});
