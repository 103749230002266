import React, { useState, useEffect, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Download } from "react-feather";
import ToolTip from "../../components/ui/ToolTip";
import { clearPurposesApiError } from "../../redux/purposes/slice";
import { ICON_SIZES } from "../../constants/common";
import { getTemplateCampaigns } from "../../redux/campaignTemplates/thunks";
import { getCampaignTypes } from "../../redux/campaignTypes/thunks";
import { clearCampaignTypesApiError } from "../../redux/campaignTypes/slice";
import { useBindValueToParam, useDebouncedValue } from "../../hooks";

import {
	SidebarPagination,
	SidebarButton,
	PopupModal,
	CmsInboxSwitcher,
	WebsiteSwitcher,
	ResultsNumber,
	Button,
	Header,
	HeaderIconBar,
	Wrapper,
	HeaderSortButtons,
} from "../../components";

import { getParentCampaignTypes } from "../../redux/parentCampaignTypes/thunks";
import { clearParentCampaignTypesApiError } from "../../redux/parentCampaignTypes/slice";
import { clearIndustriesApiError } from "../../redux/industries/slice";
import { clearCampaignsApiError, clearSelectedCampaigns } from "../../redux/campaigns/slice";
import { getCampaigns } from "../../redux/campaigns/thunks";
import { getPurposes } from "../../redux/purposes/thunks";
import { getIndustries } from "../../redux/industries/thunks";
import { selectWebsite } from "../../redux/websites/slice";
import { getWebsites } from "../../redux/websites/thunks";
import { getFonts } from "../../redux/fonts/thunks";
import { initWebfonts } from "../../redux/fonts/slice";
import { getWebsite } from "../../redux/website/thunks";
import { selectAllCampaigns } from "../../redux/campaigns/campaignsAdapter";

import {
	CampaignsFilters,
	NewBehavioralExperienceButton,
	CampaignsTable,
	RightCMSInfoBlock,
	RightCMSButtonsBlock,
	CampaignsToCloneForm,
	CMSCreativePreview,
	ArchiveCampaignForm,
	DownloadCampaignsPreviewModal,
	ScheduledWebView,
} from "./components";

import { iconSettings } from "./constants";

const params = new URL(document.location).searchParams;
const limitParam = params.get("limit");
const archivedParams = params.get("archived");
const initParams = {
	website_id: "",
	v: 0,
	q: "",
	limit: limitParam || 20,
	page: 1,
	archived: archivedParams || 0,
	campaign_purpose_id: "",
	campaign_type_id: "",
	"status[]": ["on", "off", "test", "wip"],
};

const webviewReducer = (state, action) => {
	switch (action.type) {
		case 'toggleScheduledTime': {
			return {
				showScheduleTime: !state.showScheduleTime,
				date: "",
				time: state.time,
			};
		}
		case 'updateDate': {
			return {
				showScheduleTime: state.showScheduleTime,
				date: action.nextName,
				time: state.time,
			};
		}
		case 'updateTime': {
			return {
				showScheduleTime: state.showScheduleTime,
				date: state.date,
				time: action.nextName,
			}
		}
	}
	throw Error('Unknown action: ' + action.type);
}


const CMSPage = () => {
	const {
		isLoading: campaignsIsLoading,
		isFetchError: campaignsIsFetchError,
		response: {
			meta: { total_rows: totalCampaigns = 0 },
		},
		selectedCampaigns,
	} = useSelector((state) => state.campaigns);
	const { isLoading: websiteIsLoading } = useSelector((state) => state.website);
	const campaigns = useSelector(selectAllCampaigns);

	const params = new URLSearchParams(window.location.search);

	const {
		page,
		params: { websiteId },
	} = useSelector((state) => state.page);

	const moduleCampaignType = params.get("campaign_type_id");

	const {
		purposes: { isFetchError: purposesIsFetchError },
		campaignTypes: { isFetchError: campaignTypesIsFetchError },
		industries: { isFetchError: industriesIsFetchError },
		parentCampaignTypes: { isFetchError: parentCampaignTypesIsFetchError },
	} = useSelector((state) => state);

	const { selectedWebsite } = useSelector((state) => state.websites);

	const [pageNumber, setPageNumber] = useState(initParams.page);
	const [trafficType, setTrafficType] = useState(initParams["status[]"]);
	const [searchStr, setSearchStr] = useState(initParams.q);
	const [purposeId, setPurposeId] = useState(initParams.campaign_purpose_id);
	const [campaignId, setCampaignId] = useState(moduleCampaignType || initParams.campaign_type_id);
	const [archiveFilter, setArchiveFilter] = useState(initParams.archived);
	const [versionFilter, setVersionFilter] = useState(initParams.v);
	const [pageTitle, setPageTitle] = useState("Loading...");
	const [websiteUrl, setWebsiteUrl] = useState("");
	const [view, setView] = useState("list");
	const [resultsOnPage, setResultsOnPage] = useState(initParams.limit);
	const [showCloneModal, setShowCloneModal] = useState(false);
	const [showArchiveModal, setShowArchiveModal] = useState(false);
	const [showDownloadCampaignPreview, setShowDownloadCampaignPreview] = useState(false);
	const [downloadCampaignValid, setDownloadCampaignValid] = useState(false);
	const [scheduleState, useReducerDispatch] = useReducer(webviewReducer, { showScheduleTime: false, date: "", time: "" });

	const dispatch = useDispatch();
	const debouncedSearchStr = useDebouncedValue(searchStr, 500, {
		isAwait: campaignsIsLoading,
	});

	//INFO: bind necessary values to search params

	useBindValueToParam("page", initParams.page, pageNumber, setPageNumber);
	useBindValueToParam("status[]", initParams["status[]"], trafficType, setTrafficType);
	useBindValueToParam("q", initParams.q, debouncedSearchStr, setSearchStr);
	useBindValueToParam(
		"campaign_purpose_id",
		initParams.campaign_purpose_id,
		purposeId,
		setPurposeId
	);
	useBindValueToParam("campaign_type_id", initParams.campaign_type_id, campaignId, setCampaignId);
	useBindValueToParam("limit", initParams.limit, resultsOnPage, setResultsOnPage);
	useBindValueToParam("archived", initParams.archived, archiveFilter, setArchiveFilter);
	useBindValueToParam("v", initParams.v, versionFilter, setVersionFilter);

	const hasApiError =
		campaignsIsFetchError ||
		campaignTypesIsFetchError ||
		industriesIsFetchError ||
		parentCampaignTypesIsFetchError ||
		purposesIsFetchError;

	const refreshCampaigns = async () => {
		await dispatch(
			getCampaigns({
				...initParams,
				website_id: websiteId,
				page: pageNumber,
				"status[]": trafficType,
				q: debouncedSearchStr,
				campaign_type_id: campaignId,
				campaign_purpose_id: purposeId,
				archived: archiveFilter,
				v: versionFilter,
				limit: resultsOnPage,
				include: "children",
				"fields[campaigns]":
					"name, id, active, enabled, test_mode, wip_mode, test_traffic, test_type, children, dt_edited, new_genie_json, product",
			})
		);
		dispatch(getWebsite(websiteId));
	};

	useEffect(() => {
		refreshCampaigns();
	}, [
		pageNumber,
		trafficType,
		debouncedSearchStr,
		purposeId,
		archiveFilter,
		versionFilter,
		resultsOnPage,
		websiteId,
		campaignId,
	]);

	useEffect(() => {
		const fetchWebsite = async () => {
			const response = await dispatch(getWebsite(websiteId));
			const websiteName = response?.payload?.data?.attributes?.name;
			const websiteUrl = response?.payload?.data?.attributes?.website;

			setPageTitle(websiteName);
			setWebsiteUrl(websiteUrl);
			setDownloadCampaignValid(true);
			dispatch(selectWebsite(response?.payload?.data));
		};

		fetchWebsite();
	}, [websiteId]);

	useEffect(() => {
		dispatch(clearSelectedCampaigns());
	}, [pageNumber]);

	useEffect(() => {
		dispatch(getWebsites({}));
		dispatch(getFonts({ website_id: websiteId }));
		dispatch(initWebfonts());
		dispatch(getWebsite(websiteId));
		dispatch(getPurposes());
		dispatch(getIndustries());
		dispatch(getCampaignTypes());
		dispatch(getTemplateCampaigns());
		dispatch(getParentCampaignTypes());
	}, []);

	const filterOnChange = (event) => {
		switch (event.type) {
			case "trafficType":
				setTrafficType(event.value);
				break;
			case "searchStr":
				setSearchStr(event.value);
				break;
			case "purpose":
				setPurposeId(event.value);
				break;
			case "campaign_type":
				setCampaignId(event.value);
				break;
		}
	};

	const clearApiErrors = () => {
		dispatch(clearCampaignsApiError());
		dispatch(clearPurposesApiError());
		dispatch(clearCampaignTypesApiError());
		dispatch(clearParentCampaignTypesApiError());
		dispatch(clearIndustriesApiError());
	};

	const resetFilters = () => {
		setPageNumber(initParams.page);
		setTrafficType(initParams["status[]"]);
		setSearchStr(initParams.q);
		setPurposeId(initParams.campaign_purpose_id);
		setCampaignId(initParams.campaign_type_id);
		setArchiveFilter(initParams.archived);
		setVersionFilter(initParams.v);
		setResultsOnPage(initParams.limit);
	};

	const handleDateAndTimeChange = (e, value) => {
		useReducerDispatch({
			type: value,
			nextName: e.target.value
		});
	}

	let scheduledUTCValue = "";

	if (scheduleState.date && scheduleState.time) {
		const isoDate = new Date(`${scheduleState.date} ${scheduleState.time}`);
		const formatedDate = isoDate.toISOString().split("T")[0];
		const formatedTime = isoDate.toISOString().split("T")[1];
		const splitTime = formatedTime.split(".")[0];
		scheduledUTCValue = `${formatedDate}T${splitTime}`;
	}

	return (
		<Wrapper
			left="0"
			maxWidth="100%"
			style={{
				display: "flex",
				justifyContent: "space-between",
				bottom: "0",
			}}
			title={pageTitle}
			pageTitle="Experiences"
		>
			<Aside>
				<NoPaddingContainer>
					<SidebarButton url="/websites" dataQa="websites-button" text="Websites" />
					<SidebarPagination
						pageNumber={pageNumber}
						resultsOnPage={resultsOnPage}
						setPageNumber={setPageNumber}
						totalRows={totalCampaigns}
					/>
				</NoPaddingContainer>
				<CmsInboxSwitcher page={page} websiteId={websiteId} />
				<WebsiteSwitcher />
				<CampaignsFilters
					onChange={filterOnChange}
					trafficTypeValue={trafficType}
					searchValue={searchStr}
					purposeValue={purposeId}
					campaignValue={campaignId}
					setCampaignId={setCampaignId}
					archiveFilter={archiveFilter}
					setArchiveFilter={setArchiveFilter}
					versionFilter={versionFilter}
					setVersionFilter={setVersionFilter}
				/>
				<ResultsNumber
					totalRows={totalCampaigns}
					onChange={setResultsOnPage}
					resultsNumber={resultsOnPage}
				/>
				<Button dataQA="reset-button" onClick={resetFilters} fullWidth theme="blue">
					Reset
				</Button>
			</Aside>
			<Main>
				{!campaignsIsLoading && (
					<PopupModal showModal={hasApiError} toggleModal={clearApiErrors}>
						Something went wrong!
					</PopupModal>
				)}
				<Header
					leftComponent={
						<>
							<NewBehavioralExperienceButton websiteId={websiteId} />{" "}
							{/* <UpdateMultipleCampaignCreativesButton websiteId={websiteId} /> */}
						</>
					}
					middleComponent={<HeaderSortButtons view={view} changeView={setView} />}
					rightComponent={
						<>
							<SettingsLink
								onClick={() => {
									if (downloadCampaignValid) {
										setShowDownloadCampaignPreview(true);
									}
								}}
								as="a"
							>
								<ToolTip position="bottom" text="Download Campaign Preview">
									<Download
										aria-label="Download Campaign Preview"
										size={ICON_SIZES.medium}
									/>
								</ToolTip>
							</SettingsLink>

							<HeaderIconBar icons={iconSettings(websiteId)} flag="websites" />
						</>
					}
				/>
				<CampaignsTable
					isLoading={websiteIsLoading}
					view={view}
					scheduledUTCValue={scheduledUTCValue}
					scheduledTime={scheduleState.showScheduleTime}
				/>
				<PopupModal showModal={showCloneModal} toggleModal={() => setShowCloneModal(false)}>
					<CampaignsToCloneForm
						selectedCampaigns={selectedCampaigns}
						toggleCloneModal={() => setShowCloneModal(false)}
					/>
				</PopupModal>
				<PopupModal
					showModal={showDownloadCampaignPreview}
					toggleModal={() => setShowDownloadCampaignPreview(false)}
				>
					<DownloadCampaignsPreviewModal
						selectedWebsite={selectedWebsite}
						campaigns={campaigns}
						toggleModal={() => setShowDownloadCampaignPreview(false)}
					/>
				</PopupModal>
				<PopupModal
					showModal={showArchiveModal}
					toggleModal={() => setShowArchiveModal(false)}
				>
					<ArchiveCampaignForm
						selectedCampaigns={selectedCampaigns}
						refreshCampaigns={refreshCampaigns}
						toggleArchiveModal={() => setShowArchiveModal(false)}
					/>
				</PopupModal>
			</Main>
			<Aside >
				<RightCMSInfoBlock />
				<RightCMSButtonsBlock
					disabled={!selectedCampaigns.length}
					websiteUrl={websiteUrl}
					toggleCloneModal={() => setShowCloneModal(true)}
					toggleArchiveModal={() => setShowArchiveModal(true)}
					refreshCampaigns={() => refreshCampaigns()}
				/>
				<ScheduledWebView
					onChange={handleDateAndTimeChange}
					scheduleState={scheduleState}
				/>
				<CMSCreativePreview showStepNumber />
			</Aside>
		</Wrapper>
	);
};

const Aside = styled.aside`
	width: 270px;
	background-color: #ffffff;
	overflow-y: auto;
	padding: 22px;
	opacity: ${({ disabled }) => (disabled ? "0.5" : "1")};
	pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
	&::-webkit-scrollbar {
		display: none;
	}
`;

const Main = styled.main`
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;
`;

const NoPaddingContainer = styled.div`
	margin: -22px -22px 0;
`;

const SettingsLink = styled.a`
	margin: 0px 10px;
	padding: 0px;
	background: none;
	border: 0;
	cursor: pointer;
	width: ${(props) => (props.width ? props.width : "auto")};
	&:last-child {
		margin: 0 0 0 10px;
	}
	i,
	svg {
		color: #191919;
		&:hover {
			color: #fff;
		}
		&:hover + span {
			color: #fff;
		}
	}

	span {
		font-size: 11px;
		display: inline-block;
		margin: 0 0 0 10px;
		position: relative;
		top: -3px;
	}
`;

export default CMSPage;
