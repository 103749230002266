import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { toast } from "react-toastify";
import { Plus, X } from "react-feather";

import { selectAllWebsites } from "../../../redux/websites/websitesAdapter";
import { crossSiteDuplicateCampaign } from "../../../redux/campaigns/thunks";
import { Button, SearchableSelect } from "../../../components";
import { FormInput } from "../../../components/form";
import { ICON_SIZES } from "../../../constants/common";

const CampaignsToCloneWindow = ({ selectedCampaigns, toggleCloneModal }) => {
	const [websiteList, addToWebsiteList] = useState([]);
	const [websiteIds, setWebsiteIds] = useState("");

	const websites = useSelector(selectAllWebsites);
	const dispatch = useDispatch();

	const parentCampaigns = selectedCampaigns.filter((campaign) => {
		return campaign.attributes?.["test-type"] === "parent";
	});
	const options = websites.map((site) => {
		return {
			value: site.attributes.id,
			label: site.attributes.name,
		};
	});

	const changeWebsite = (websiteId) => {
		if (!websiteList.includes(websiteId)) {
			addToWebsiteList([...websiteList, websiteId]);
		}
	};

	const addToWebsite = () => {
		const filterArray = [];
		const ids = websiteIds.split(",").map((str) => str.replace(/\s/g, ""));
		ids.map((selectedId) => {
			filterArray.push(
				websites.find(({ id }) => {
					if (id === selectedId) {
						return true;
					}
					return false;
				})
			);
		});

		filterArray
			.filter((item) => !!item)
			.map((website) => {
				if (!websiteList.includes(website?.attributes?.id)) {
					addToWebsiteList([...websiteList, website?.attributes?.id]);
				}
			});
	};

	const removeItem = (websiteId) => {
		addToWebsiteList(websiteList.filter((item) => item !== websiteId));
	};

	const cloneCampaign = async () => {
		const response = await dispatch(
			crossSiteDuplicateCampaign({
				targetSiteIds: websiteList,
				orderedParentCampaigns: parentCampaigns,
				linkCampaigns: 0,
			})
		);

		const { requestStatus } = response?.meta;

		if (requestStatus === "fulfilled") {
			toggleCloneModal();
			toast.success("Campaign cloned successfully", { autoClose: 1000 });
		} else {
			toast.error("An error occurred", { autoClose: 1000 });
		}
	};

	return (
		<>
			<WebsitesList>
				<Header>Campaigns to Clone</Header>
				{parentCampaigns.map((campaign) => (
					<Divider key={campaign.id}>
						<Label>{campaign.attributes?.name}</Label>
						<Label isId>{campaign.id}</Label>
					</Divider>
				))}
			</WebsitesList>
			<div>
				<Header>Websites</Header>
				<SearchableSelect
					placeholder="Search for a website"
					options={options}
					onChange={changeWebsite}
					showId
					label="Search for a website"
					value=""
				/>
				<DividerWebsiteIds>
					<FormInput
						placeholder="Enter multiple website IDs separated by commas"
						onChange={setWebsiteIds}
						value={websiteIds}
					/>
					<Button onClick={addToWebsite} theme="blue">
						<Plus aria-label="Add Button" size={ICON_SIZES.medium} />
					</Button>
				</DividerWebsiteIds>
				<WebsiteDivider>
					{websiteList.length > 0 ? (
						websiteList.map((item, i) => {
							const name = websites.filter(
								(websites) => websites.attributes.id === item
							);
							return (
								<PillDisplay key={i}>
									<Link href={`/experiences/cms?website_id=${item}`}>
										{name[0]?.attributes?.name}
									</Link>
									<Delete onClick={() => removeItem(item)}>
										<X aria-label="Delete Button" size={ICON_SIZES.small} />
									</Delete>
								</PillDisplay>
							);
						})
					) : (
						<ListLabel>No Websites Selected</ListLabel>
					)}
				</WebsiteDivider>
				<ButtonWrapper>
					<Button
						theme="blue"
						onClick={cloneCampaign}
						disabled={websiteList.length === 0}
					>
						Clone to Websites
					</Button>
				</ButtonWrapper>
			</div>
		</>
	);
};

const Link = styled.a`
	&& {
		display: inline-block;
		padding: 4px 15px;
		border-radius: 10px 0 0 10px;
		background: #dddddd;
		&:hover {
			background: #cdcdcd;
		}
	}
`;

const ListLabel = styled.div`
	color: #9d9a9a;
	font-size: 12px;
`;

const WebsiteDivider = styled.div`
	border: 1px solid #e8e8e8;
	padding: 10px;
	margin: 0 0 20px 0;
	display: flex;
	flex-wrap: wrap;
	grid-gap: 5px 0;
	max-width: 482px;
`;

const Delete = styled.div`
	display: inline-flex;
	padding: 6px 5px;
	border-radius: 0 10px 10px 0;
	background: #dddddd;
	cursor: pointer;
	justify-content: center;
	align-items: center;
	&:hover {
		background: #cdcdcd;
	}
`;

const PillDisplay = styled.div`
	display: inline-flex;
	align-items: center;
	margin-right: 6px;
	font-size: 10px;
`;

const ButtonWrapper = styled.div`
	display: flex;
	flex-direction: row;
	button {
		max-width: 150px;
	}
`;

const WebsitesList = styled.div`
	position: relative;
	height: 100%;
	font-size: 12px;
	overflow-y: auto;
	flex: 1;
	margin-bottom: 20px;
`;

const DividerWebsiteIds = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	button {
		height: 42px !important;
		width: 42px !important;
	}
`;

const Divider = styled.div`
	padding: 10px;
	border: 1px solid #e8e8e8;
	display: flex;
	flex-direction: row;
	margin-bottom: 5px;
`;

const Label = styled.div`
	font-size: 11px;
	font-weight: 600;
	margin-left: ${({ isId }) => (isId ? "auto" : "0")};
	color: ${({ isId }) => (isId ? "#9d9a9a" : "#191919")};
`;

const Header = styled.div`
	display: block;
	margin: 5px 0;
	padding: 0;
	font-size: 18px;
	font-weight: bold;
	width: 100%;
	text-align: left;
	color: #535252;
`;

CampaignsToCloneWindow.propTypes = {
	selectedCampaigns: PropTypes.arrayOf(
		PropTypes.shape({
			attributes: PropTypes.shape({
				name: PropTypes.string,
			}).isRequired,
			id: PropTypes.string.isRequired,
		})
	).isRequired,
	toggleCloneModal: PropTypes.func.isRequired,
};

export default CampaignsToCloneWindow;
